<template>
  <div id="app">
    <main-header />
    <el-container class="main-container">
      <main-sidebar />
      <el-main class="main-content">
        <router-view/>
      </el-main>
    </el-container>
    <main-chat/>
    <!-- <contact-list/> -->
  </div>
</template>
<script>
import MainHeader from '../../components/mainHeader'
import MainSidebar from '../../components/mainSidebar'
import mainChat from '../../components/mainChat'
// import contactList from '../../components/contactList'

export default {
  components: {
    MainHeader,
    MainSidebar,
    mainChat
    // contactList
  }
}
</script>
