<template>
  <div class="_tokchat-container">
    <div :class="userListWindowCollapsed ? '_tokchat-window-item collapsed' : '_tokchat-window-item'">
      <header class="_tokchat-has-new-chat _tokchat-header" :class="this.active_users.reduce((n, el) => n + el.unSeenChatCount, 0) > 0 ? 'hasNewChat' : ''">
        <h3 @click="userListWindowCollapsed = !userListWindowCollapsed">Chat
        </h3>
        <input type="text" v-model="search" @input="onSearch" class="search-input" placeholder="Хэрэглэгч хайх">
      </header>
      <div class="_tokchat-body">
        <div class="_tokchat-user-list">
          <div class="_tokchat-user-item online" v-for="user in active_users" :key="user.sub">
            <div @click="showChat = !showChat" class="_tokchat-user-info">
              <div style="font-weight: bold;" @click="room(user)" :class="(itemA === user.sub) ? 'active' : '' ">{{user.name}}
                <span v-if="user.unSeenChatCount > 0">({{ user.unSeenChatCount }})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="_tokchat-window-item collapsed" v-show="showChat">
      <div v-if="selectedUser" class="chat_header">
        {{ selectedUser.name }}
        <span class="close-icon" @click="closeChat"></span>
      </div>
      <div class="chat-body">
          <div>
            <div v-for="(item, index) in chatData.chats" :key="index" :ref="item.id">
              <div v-if="item.from === loggedUser.attributes.sub">
                <div @click="toggle = !toggle" class="myChat">
                  <div style="margin-left: 20px; padding-bottom: 10px;">{{ item.text }}
                    <p v-show='toggle' style="font-size: 10px;">{{item.date}}</p>
                  </div>
                </div>
                <span v-if="item.seen === true && chatData.chats.length - 1 === index" style="font-size: 11px; margin-left: 90%">seen</span>
              </div>
              <div @click="toggle = !toggle" class="chatText" v-else>
                  <div style="margin-left:20px; padding-bottom: 10px">{{ item.text }}
                    <p v-show='toggle' style="font-size: 10px;" >{{item.date}}</p>
                  </div>
              </div>
          </div>
          </div>
      </div>
      <div class="_tokchat-window-footer">
        <input v-on:keyup.enter="send" type="text" class="chat-input"  v-model="text" placeholder="Type a new message">
        <button class="_tokchat-send-button" @click="send">Илгээх</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getUser, getToken } from '../utils/auth'
import axios from 'axios'

export default {
  components: {
  },
  async created () {
    if (this.$root.socket) {
      await this.$root.initSocket()
    }
    await this.$root.socketTokenRefresher()
    getUser().then(async (user) => {
      if (user && user.attributes && user.attributes.email) {
        this.user_uid = user.attributes.email
      }
      const token = await getToken()
      const response = await axios.get('https://chat-api.toktokmall.mn/users', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
      if (response.data.status === 'success') {
        this.active_users = response.data.data
      }
      this.$root.socket.on('connect', () => {
      })
      this.$root.socket.on('newChat', async (chat) => {
        if (this.chatData.chat_id === chat.chat_id && this.showChat) {
          this.chatData.chats.push(chat)
          this.$root.socket.emit('seenChat', chat)
          setTimeout(() => {
            this.scrollToElement(this.chatData.chats[this.chatData.chats.length - 1])
          }, 100)
        } else {
          const fromUserIndex = this.active_users.findIndex(user => user.sub === chat.from)
          if (fromUserIndex !== -1) {
            this.active_users[fromUserIndex].unSeenChatCount += 1
          } else {
            const responseOneUser = await axios.get('https://chat-api.toktokmall.mn/users/' + chat.from, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
              }
            })
            if (responseOneUser.data.status === 'success') {
              this.active_users = [responseOneUser.data.data, ...this.active_users]
            }
          }
        }
      })
    })
  },
  async mounted () {
    getUser().then((user) => {
      this.loggedUser = user
    })
  },
  data () {
    return {
      showChat: false,
      chat: false,
      userListWindowCollapsed: false,
      search: '',
      selectedUser: null,
      loggedUser: null,
      active_users: [],
      chatData: [],
      user_uid: '',
      text: '',
      toggle: false,
      itemA: '',
      insertMessage: '',
      sendChat: []
    }
  },
  methods: {
    closeChat () {
      this.showChat = false
    },
    async onSearch () {
      const query = '?name=' + this.search
      const token = await getToken()
      const response = await axios.get('https://chat-api.toktokmall.mn/users' + query, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
      if (response.data.status === 'success') {
        this.active_users = response.data.data
      }
    },
    scrollToElement (chat) {
      const el = this.$refs[chat.id][0]
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },
    async room (item) {
      this.itemA = item.sub
      const body = {
        user_sub: item.sub,
        role: item.role,
        user_name: item.name,
        iss: item.iss,
        email: item.email,
        status: item.status
      }
      const token = await getToken()
      const response = await axios.post('https://chat-api.toktokmall.mn/room', body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
      if (response.data.status === 'success') {
        this.chatData = response.data.data
        setTimeout(() => {
          this.scrollToElement(this.chatData.chats[this.chatData.chats.length - 1])
        }, 100)
      }
      this.$root.socket.emit('seenChat', this.chatData.chats[this.chatData.chats.length - 1])
      item.unSeenChatCount = 0
      this.selectedUser = item
      this.showChat = true
    },
    async send () {
      const body = {
        roomId: this.chatData.chat_id,
        chat: this.text
      }
      const token = await getToken()
      const response = await axios.post('https://chat-api.toktokmall.mn/chat/send', body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
      if (response.data.status === 'success') {
        this.sendChat = response.data.data
      } else if (response.data.status === 'unsuccess') {
        this.alertReporter('Алдаа', response.data.message, 'error')
      }
      this.text = 'on enter event'
      this.text = ''
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    }
  }
}
</script>
<style scoped>
._tokchat-window-footer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 15px 10px;
  border-top: 1px solid #e0e0e0;
}
._tokchat-send-button{
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  width: 25%;
  height: 35px;
  cursor: pointer;
}
.chat-input {
  height: 35px;
  font-size: 16px;
  width: 65%;
  padding-left: 15px;
  background-color: #fff;
  border-radius: 15px;
  border: none;
  font: inherit;
  text-decoration: none;
  border: 1px solid #e0e0e0;
}
@keyframes flashingBackground{
  0%{ background-color: #769aca;}
  50%{ background-color: #5685c2;}
  100%{ background-color: #3e7ccc;}
}
.hasNewChat {
  animation: flashingBackground 1s infinite;
}
.active {
  background-color: #000000;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px
}
._tokchat-container {
  position: fixed;
  bottom: 0;
  right: 20px;
  font-family: Arial, Helvetica, sans-serif !important;
  width: auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  z-index: 9999;
}
._tokchat-container > div{
  margin-left: 20px;
}
._tokchat-window-item {
  border-radius: 10px 10px 0 0;
  background-color: #ffffff;
  box-shadow: 0px -2px 8px -7px rgba(0,0,0,0.75);
  width: 80px;
  box-sizing: border-box;
  overflow: hidden;
  height: 50px;
  transition: height, width 300ms;
  bottom: 0;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 10%);
  border: 1px solid #e0e0e0;
  border-bottom: none;
}
._tokchat-window-item.collapsed {
  height: auto;
  width: 320px;
}
._tokchat-header {
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}
._tokchat-header h3{
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  cursor: pointer;
}
._tokchat-header.hasNewChat h3{
  color: #fff;
}
._tokchat-header input {
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  color: #666666;
}
._tokchat-header input:focus-visible {
  outline-color: #888888;
  outline-width: 1px;
}
._tokchat-header input::placeholder{
  color: #a0a0a0;
}
._tokchat-body {
  overflow-y: auto;
  padding: 20px;
  height: 350px;
}
._tokchat-user-list {}
._tokchat-user-list ._tokchat-user-item {
  padding-left: 20px;
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
}
._tokchat-user-list ._tokchat-user-item::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #a0a0a0;
  left: 0;
  top: 50%;
  margin-top: -5px;
}
._tokchat-user-list ._tokchat-user-item.online::before {
  background-color: rgb(25, 190, 33);
}
._tokchat-user-list ._tokchat-user-item ._tokchat-user-info {}
._tokchat-user-list ._tokchat-user-item ._tokchat-user-info h3{
  margin: 0;
  font-size: 14px;
}
._tokchat-user-list ._tokchat-user-item ._tokchat-user-info p{
  font-size: 12px;
  color: #a0a0a0;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.chat-body {
  overflow-y: auto;
  padding: 20px;
  height: 0;
  margin-bottom: -40px;
  transition: height 300ms;
}
.chat-body {
  height: 343px;
  margin-bottom: 0;
}
.chatSend:hover {
  font-size: 30px;
}
.myChat {
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  position: relative;
  hyphens: auto;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 70px;
  max-width: 200px;
  background-color: #1877f2;
  border-radius: 20px;
  color: white;
  /* margin-bottom: 10px; */
  padding-top: 10px;
  padding-right: 15px;
}
.chatText {
  hyphens: auto;
  display: flex;
  max-width: 200px;
  word-wrap: break-word;
  white-space: pre-wrap;
  position: relative;
  background-color: #E4E6EB;
  margin-top:10px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  color: black;
  padding-top: 10px;
  padding-right: 15px;
  margin-bottom: 10px;
}
.chat_header {
  padding: 1rem;
  font-size: 16px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
}
.close-icon {
  position: absolute;
  right: 10px;
  top: 15px;
  width: 20px;
  height: 20px;
  opacity: 0.3;
  cursor: pointer;
}
.close-icon:hover {
  opacity: 1;
}
.close-icon:before, .close-icon:after {
  position: absolute;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #333;
  right: 15px;
}
.close-icon:before {
  transform: rotate(45deg);
}
.close-icon:after {
  transform: rotate(-45deg);
}
.selectedUser {
  margin-left: 20px;
  font-family: monospace;
  font-size: 20px;
  font-weight: bold;
}
.closeChat {
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}
.closeChat:hover {
  color: red;
}
</style>
