<template>
  <div>
    <div class="supplier-panel-container">
        <router-view />
    </div>
  </div>
</template>
<script>
export default {
}
</script>
