<template>
  <el-aside :class="isSun ? 'main-sidebar' : 'main-sidebar1'">
    <ul class="main-menu">
      <li :class="(($route.name === 'homePage') ? 'active': '')">
        <router-link to="/homePage">
          <home-icon class="icon" :size="24" />
          <span>Нүүр</span>
        </router-link>
      </li>
      <li :class="(( ['salesReport', 'cancelReport'].includes($route.name)) ? 'active': '')">
        <router-link to="/sales-report">
          <note-icon class="icon" :size="24"/>
          <span class="text">Тайлан</span>
        </router-link>
        <ul>
          <li :class="(( ['salesReport'].includes($route.name )) ? 'active': '')">
            <router-link to="/sales-report">
              <span class="text">Борлуулалт</span>
            </router-link>
          </li>
          <li :class="(( ['cancelReport'].includes($route.name )) ? 'active': '')">
            <router-link to="/cancel-report">
              <span class="text">Цуцлалт</span>
            </router-link>
          </li>
        </ul>
      </li>
      <!-- <li :class="(( [
        'supplierList',
        'supplierEdit'
        ].includes($route.name )) ? 'active': '')">
      <router-link to="/supplier-list">
        <ssh-icon class="icon" :size="20" />
        <span class="text">Нийлүүлэгч</span>
      </router-link>
      </li> -->
    <!-- <li :class="(( [
          'brandList',
          'brandAddForm',
          'editBrand'].includes($route.name )) ? 'active': '')">
        <router-link to="/brandList">
          <registry-icon class="icon" :size="24" />
          <span class="text">Брэнд</span>
        </router-link>
      </li> -->
      <li :class="(( [
        'products',
        'updateProduct',
        'addProduct',
        'inventory',
        'stockDetail',
         'remainderReport'
        ].includes($route.name )) ? 'active': '')">
          <router-link to="/products">
          <format-list-bulleted-icon class="icon" :size="24" />
            <span class="text">Бараа материал</span>
          </router-link>
          <ul>
            <li :class="(( [
              'products',
              'updateProduct',
              'addProduct'
              ].includes($route.name )) ? 'active': '')">
                <router-link to="/products">
                  <span class="text">Бүтээгдэхүүн</span>
                </router-link>
            </li>
            <li :class="(( [
              'inventory',
              'stockDetail'
              ].includes($route.name )) ? 'active': '')">
                <router-link to="/inventory">
                  <span class="text">Хувилбарууд</span>
                </router-link>
            </li>
            <li :class="(( ['remainderReport'].includes($route.name )) ? 'active': '')">
              <router-link to="/remainder-report">
                <span class="text">Үлдэгдэл</span>
              </router-link>
            </li>
          </ul>
      </li>
      <!-- <li :class="(( [
        'addPrice'
        ].includes($route.name )) ? 'active': '')">
      <router-link to="/add-price">
        <currency-mnt-icon class="icon" :size="20" />
        <span class="text">Үнэ өөрчлөх</span>
      </router-link>
      </li> -->
      <li :class="(( [
          'orderList'].includes($route.name )) ? 'active': '')">
        <router-link to="/order">
          <order-icon class="icon" :size="24" />
          <span class="text">Нийт захиалгууд</span>
        </router-link>
      </li>
      <!-- <li :class="(( [
          'remainder'].includes($route.name )) ? 'active': '')">
        <router-link to="/remainder">
          <number-icon class="icon" :size="24" />
          <span class="text">Үлдэгдэл</span>
        </router-link>
      </li> -->
      <li :class="(( [
          'clientContactEdit',
          'clientContactAdd',
          'clientContactList'].includes($route.name )) ? 'active': '')">
        <router-link to="/client-contact-list">
          <contacts-icon class="icon" :size="24" />
          <span class="text">Харилцагч</span>
        </router-link>
      </li>
      <!-- <li :class="(( [
          'Schedule'].includes($route.name )) ? 'active': '')">
        <router-link to="/schedule">
          <calendar-month class="icon" :size="24" />
          <span class="text">Цагийн хуваарь</span>
        </router-link>
      </li> -->
      <li :class="(( [
          'Warehouse',
          'AddWarehouse',
          'UpdateWarehouse'].includes($route.name )) ? 'active': '')">
        <router-link to="/warehouse">
          <warehouse class="icon" :size="24" />
          <span class="text">Агуулах</span>
        </router-link>
      </li>
    </ul>
    <i :class="isSun ? 'el-icon-moon position mr5 iconSun' : 'el-icon-sunny mr5 position iconDark'" @click="isSun = !isSun"></i>
    <contact-list :isSelect="isSun"/>
  </el-aside>
</template>
<script>
import HomeIcon from 'vue-material-design-icons/Home.vue'
// import SshIcon from 'vue-material-design-icons/Ssh.vue'
import ContactsIcon from 'vue-material-design-icons/Contacts.vue'
import OrderIcon from 'vue-material-design-icons/BasketFill.vue'
// import CalendarMonth from 'vue-material-design-icons/CalendarMonth.vue'
// import RegistryIcon from 'vue-material-design-icons/City.vue'
import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted.vue'
import Warehouse from 'vue-material-design-icons/Warehouse.vue'
import NoteIcon from 'vue-material-design-icons/Note.vue'
import contactList from '../components/contactList'

export default {
  components: {
    // CalendarMonth,
    HomeIcon,
    OrderIcon,
    NoteIcon,
    // NumberIcon,
    // RegistryIcon,
    ContactsIcon,
    // SshIcon,
    FormatListBulletedIcon,
    Warehouse,
    contactList
    // CurrencyMntIcon
  },
  data () {
    return {
      route: '',
      isSun: false
    }
  }
}
</script>
<style>
  .iconDark {
    color: white;
    font-size: 1.5rem;
  }
  .iconSun {
    color: rgb(48, 65, 86);
    font-size: 1.5rem;
  }
  .position {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
</style>
