// URL
const BASE_PATH = 'https://rest.toktok.mn/client-pd-dev'
const BASE_SECOND_PATH = 'https://api.toktokmall.mn/api'

// home
const BASE_GET_PRODUCTS_STAT = '/getProductsStat'
const BASE_GET_REVIEWS = '/getReviews'
const BASE_GET_STATS = '/getStats'
const BASE_GET_CLIENT_INFO = 'https://blog.toktok.mn/ghost/api/v2/content/posts/?key=84f9269f48396b98fc6f893641&filter=tag:toktokmallclient&limit=5'

// supplier uusgeh
const BASE_PRODUCTS_WITH_VARIANT = '/productsWithVariant'
// Supplier
const BASE_SUPPLIER = '/supplier'
const BASE_GET_ONESUPPLIER_BRANDS = '/getSupplierBrands'
const BASE_GET_SUPPLIERS_BY_WAREHOUSE_ID = '/getSuppliersByWarehouseId'
const BASE_GET_BRANDS_BY_SUPLLIER_IDS = '/getBrandsBySupplierIds'
// Price
const BASE_ACCEPTED_PRICE = '/price'
// product nemeh
const BASE_POST_CREATE_PRODUCT = '/product'
const BASE_GET_CATEGORIES_TREE = '/category'
const BASE_GET_PROPERTIES_NAME = '/getPropertiesName'
const BASE_GET_PROPERTIES_VALUE = '/getPropertiesValue'
const BASE_POST_OPTION_NAME = '/getOptionsName'
const BASE_POST_OPTION_VALUE = '/getOptionsValue'
const BASE_GET_TAG = '/tag'
// contact
const BASE_GET_EMPLOYEE = '/employee'
const BASE_GET_TOKTOK_EMPLOYEES = '/toktokEmployees'
// borluulaltiin tailan
const BASE_POST_REPORT = '/report'
//
const BASE_GET_PRICE = '/price'
//
const BASE_POST_UPLOAD_IMG = '/file/imageUpload'
// unelgeenii delgerengui hesgiin buteegdehuun tus buriin unelgeenii huselt
const BASE_GET_REVIEWS_BY_PRODUCT_ID = '/getReviewsByProductId'
//
const BASE_GET_SALES_REPORT = '/order/getSalesReport'
// Users
const BASE_GET_USERS = '/getUsers'
const BASE_CHANGE_PASSWORD = '/changePassword'
// Groups
const BASE_GET_GROUPS = '/getGroups'
const BASE_CREATE_GROUP = '/createGroup'
const BASE_DELETE_GROUP = '/deleteGroup'
const BASE_ADD_TO_GROUP = '/addUserToGroup'
// Inventory
const BASE_GET_VARIANTS_BY_SUPPLIER_ID = '/productsWithVariant'
const BASE_VARIANTS_BY_SUPPLIER_ID = '/variant/getVariantsBySupplierId'
const BASE_GET_VARIANTS_NO_PAG = '/prodWithVarNoPagination'
const BASE_GET_ONE_VARIANT = '/variant'
const BASE_POST_CHANGE_STATUS = '/updateVariantStatus'
const BASE_POST_ADD_VARIANT = '/variant'
// Product
const BASE_GET_PRODUCT_BY_SUPPLIER_ID = '/product/getProductsBySupplierId'
const BASE_GET_PRODUCT_EXEC_BY_SUPPLIER_ID = '/product/getProductsExecBySupplierId'
const BASE_GET_ONE_PRODUCT = '/product'
const BASE_UPDATE_PRODUCT_STATUS = '/updateProductStatus'

// Transfer
const BASE_GET_TRANSFERS_BY_SUPPLIER_ID = '/getTransfersBySupplierId'
const BASE_GET_ONE_TRANSFER = '/transfer'
const BASE_GET_CHANGE_SUPPLIER_STATUS = '/changeSupplierStatus'

// RETURN
const BASE_GET_RETURNS_BY_SUPPLIER_ID = '/getReturnsBySupplierId'
const BASE_GET_ONE_RETURN = '/return'
// Warehouse
const BASE_GET_WAREHOUSES_BY_SUPPLIER_ID = '/getWarehousesBySupplierId'
const BASE_GET_WAREHOUSE_BY_WAREHOUSE_ID = '/getWarehouseByWarehouseId'
const BASE_POST_WAREHOUSE = '/warehouse'
const BASE_PUT_WAREHOUSE = '/warehouse/'
const BASE_GET_ONE_WAREHOUSE = '/warehouse/'
const BASE_DELETE_WAREHOUSE = '/warehouse/'

// Brand
const BASE_BRAND = '/brand'
// Order
const BASE_GET_ORDER_BY_SUPPLIER_ID = '/order'
const BASE_GET_ORDER_ITEMS_BY_ORDER_ID = '/getOrderItemsByOrderId'
const BASE_GET_ORDERS_BY_DRIVER_NAME = '/getOrdersByDriverName'

export default {
  BASE_PATH: BASE_PATH,
  BASE_SECOND_PATH: BASE_SECOND_PATH,
  //
  BASE_POST_REPORT: BASE_POST_REPORT,
  BASE_PRODUCTS_WITH_VARIANT: BASE_PRODUCTS_WITH_VARIANT,
  //
  BASE_GET_PRODUCTS_STAT: BASE_GET_PRODUCTS_STAT,
  BASE_GET_REVIEWS: BASE_GET_REVIEWS,
  BASE_GET_STATS: BASE_GET_STATS,
  BASE_GET_SALES_REPORT: BASE_GET_SALES_REPORT,
  BASE_GET_CLIENT_INFO: BASE_GET_CLIENT_INFO,
  //
  BASE_GET_PRICE: BASE_GET_PRICE,
  //
  BASE_GET_REVIEWS_BY_PRODUCT_ID: BASE_GET_REVIEWS_BY_PRODUCT_ID,
  //
  BASE_POST_CREATE_PRODUCT: BASE_POST_CREATE_PRODUCT,
  BASE_GET_CATEGORIES_TREE: BASE_GET_CATEGORIES_TREE,
  BASE_GET_PROPERTIES_NAME: BASE_GET_PROPERTIES_NAME,
  BASE_GET_PROPERTIES_VALUE: BASE_GET_PROPERTIES_VALUE,
  BASE_POST_OPTION_NAME: BASE_POST_OPTION_NAME,
  BASE_POST_OPTION_VALUE: BASE_POST_OPTION_VALUE,
  BASE_GET_TAG: BASE_GET_TAG,
  //
  BASE_GET_EMPLOYEE: BASE_GET_EMPLOYEE,
  BASE_GET_TOKTOK_EMPLOYEES: BASE_GET_TOKTOK_EMPLOYEES,
  //
  BASE_POST_UPLOAD_IMG: BASE_POST_UPLOAD_IMG,
  //
  BASE_GET_VARIANTS_BY_SUPPLIER_ID: BASE_GET_VARIANTS_BY_SUPPLIER_ID,
  BASE_VARIANTS_BY_SUPPLIER_ID: BASE_VARIANTS_BY_SUPPLIER_ID,
  BASE_GET_VARIANTS_NO_PAG: BASE_GET_VARIANTS_NO_PAG,
  BASE_GET_ONE_VARIANT: BASE_GET_ONE_VARIANT,
  BASE_POST_CHANGE_STATUS: BASE_POST_CHANGE_STATUS,
  BASE_POST_ADD_VARIANT: BASE_POST_ADD_VARIANT,
  //
  BASE_GET_PRODUCT_BY_SUPPLIER_ID: BASE_GET_PRODUCT_BY_SUPPLIER_ID,
  BASE_GET_PRODUCT_EXEC_BY_SUPPLIER_ID: BASE_GET_PRODUCT_EXEC_BY_SUPPLIER_ID,
  BASE_GET_ONE_PRODUCT: BASE_GET_ONE_PRODUCT,
  BASE_UPDATE_PRODUCT_STATUS: BASE_UPDATE_PRODUCT_STATUS,
  //
  BASE_GET_TRANSFERS_BY_SUPPLIER_ID: BASE_GET_TRANSFERS_BY_SUPPLIER_ID,
  BASE_GET_ONE_TRANSFER: BASE_GET_ONE_TRANSFER,
  BASE_GET_CHANGE_SUPPLIER_STATUS: BASE_GET_CHANGE_SUPPLIER_STATUS,
  //
  BASE_GET_RETURNS_BY_SUPPLIER_ID: BASE_GET_RETURNS_BY_SUPPLIER_ID,
  BASE_GET_ONE_RETURN: BASE_GET_ONE_RETURN,
  //
  BASE_GET_WAREHOUSES_BY_SUPPLIER_ID: BASE_GET_WAREHOUSES_BY_SUPPLIER_ID,
  BASE_GET_WAREHOUSE_BY_WAREHOUSE_ID: BASE_GET_WAREHOUSE_BY_WAREHOUSE_ID,
  BASE_POST_WAREHOUSE: BASE_POST_WAREHOUSE,
  BASE_PUT_WAREHOUSE: BASE_PUT_WAREHOUSE,
  BASE_GET_ONE_WAREHOUSE: BASE_GET_ONE_WAREHOUSE,
  BASE_DELETE_WAREHOUSE: BASE_DELETE_WAREHOUSE,
  //
  BASE_BRAND: BASE_BRAND,
  //
  BASE_GET_ORDER_BY_SUPPLIER_ID: BASE_GET_ORDER_BY_SUPPLIER_ID,
  BASE_GET_ORDER_ITEMS_BY_ORDER_ID: BASE_GET_ORDER_ITEMS_BY_ORDER_ID,
  BASE_GET_ORDERS_BY_DRIVER_NAME: BASE_GET_ORDERS_BY_DRIVER_NAME,
  //
  BASE_GET_USERS: BASE_GET_USERS,
  BASE_CHANGE_PASSWORD: BASE_CHANGE_PASSWORD,
  //
  BASE_SUPPLIER: BASE_SUPPLIER,
  BASE_GET_ONESUPPLIER_BRANDS: BASE_GET_ONESUPPLIER_BRANDS,
  BASE_GET_SUPPLIERS_BY_WAREHOUSE_ID: BASE_GET_SUPPLIERS_BY_WAREHOUSE_ID,
  BASE_GET_BRANDS_BY_SUPLLIER_IDS: BASE_GET_BRANDS_BY_SUPLLIER_IDS,
  //
  BASE_GET_GROUPS: BASE_GET_GROUPS,
  BASE_CREATE_GROUP: BASE_CREATE_GROUP,
  BASE_DELETE_GROUP: BASE_DELETE_GROUP,
  BASE_ADD_TO_GROUP: BASE_ADD_TO_GROUP,
  //
  BASE_ACCEPTED_PRICE: BASE_ACCEPTED_PRICE
}
