<template>
  <div>
    <el-row :gutter="0" type="flex" justify="center" align="middle">
      <el-col :span="12" :xs="24">
        <div class="login-panel-container">
          <router-view />
        </div>
      </el-col>
      <el-col :span="12" class="hidden-xs-only">
        <div class="login-right-bar">
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
}
</script>
