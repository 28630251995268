import { mainRequests } from './custom'
import config from '../config'
export default {
  getProductsWithVariant (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_PRODUCTS_WITH_VARIANT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateProductsStatus (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_CREATE_PRODUCT + '/updateProductsStatus', body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateVariant (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_GET_ONE_VARIANT + '/' + body.variant.variant_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  salesReport (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_REPORT + '/reportExec', body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  salesReportNoPag (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_REPORT + '/reportExecNoPagination', body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getStats (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_STATS + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  checkEbarimt (regno) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet('https://rest.toktok.mn/other/checkMerchant?regno=' + regno)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getTag (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_TAG + '/' + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOptionName (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_OPTION_NAME, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOptionValue (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_OPTION_VALUE, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPropertiesValue (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_PROPERTIES_VALUE + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPropertiesName (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_PROPERTIES_NAME, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCategoriesAsTree (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_CATEGORIES_TREE + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Supplier
  getSuppliers (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_SUPPLIER + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteSupplier (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_SUPPLIER + '/' + body.supplierId + '?desc=' + body.desc)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addSupplier (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_SUPPLIER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getBrandsBySupplierIds (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_BRAND, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateSupplier (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_SUPPLIER + '/' + body.supplier_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneSupplier (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_SUPPLIER + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSupplierBrands (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ONESUPPLIER_BRANDS + '?supplier_id=' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  acceptedPrices (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_ACCEPTED_PRICE, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createBrand (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_BRAND, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateBrand (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_BRAND + '/' + body.brand_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addProduct (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_CREATE_PRODUCT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteProduct (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_POST_CREATE_PRODUCT + '/' + body.id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  imageUpload (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_UPLOAD_IMG, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  updateChangeStatus (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_CHANGE_STATUS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeSupplierStatus (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_GET_CHANGE_SUPPLIER_STATUS + '/' + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Product Requests
  getProductsBySupplierId (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_PRODUCT_BY_SUPPLIER_ID, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProductsExecBySupplierId (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_PRODUCT_EXEC_BY_SUPPLIER_ID, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getClientInfo () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_GET_CLIENT_INFO)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getBrand (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_BRAND + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteBrand (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_BRAND + '/' + body.brand_id + '?desc=' + body.desc)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getRemainderBySupplierId (query, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_VARIANTS_BY_SUPPLIER_ID + query, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getToktokEmployees (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_EMPLOYEE + config.BASE_GET_TOKTOK_EMPLOYEES + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getEmployees (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_EMPLOYEE + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneEmployees (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_EMPLOYEE + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUpdateEmployees (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_GET_EMPLOYEE + '/' + body.employee_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createClientEmployees (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_EMPLOYEE, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteEmployees (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_GET_EMPLOYEE + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  variantsBySupplierId (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_VARIANTS_BY_SUPPLIER_ID, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addVariant (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_ADD_VARIANT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getVariantsNoPag (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_VARIANTS_NO_PAG, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getWarehousesBySupplierId (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_WAREHOUSES_BY_SUPPLIER_ID, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createWarehouse (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_WAREHOUSE, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateWarehouse (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_PUT_WAREHOUSE + body.warehouse_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteWarehouse (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_DELETE_WAREHOUSE + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneWarehouse (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ONE_WAREHOUSE + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getTransfersBySupplierId (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_TRANSFERS_BY_SUPPLIER_ID, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneVariant (variantId, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ONE_VARIANT + '/' + variantId, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getWarehouseByWarehouseId (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_WAREHOUSE_BY_WAREHOUSE_ID + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getReturnsBySupplierId (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_RETURNS_BY_SUPPLIER_ID, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateProduct (productId, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_GET_ONE_PRODUCT + '/' + productId, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneProduct (productId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ONE_PRODUCT + '/' + productId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateProductStatus (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_UPDATE_PRODUCT_STATUS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateStockStatus (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_POST_CHANGE_STATUS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneTransfer (transferId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ONE_TRANSFER + '/' + transferId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneReturn (transferId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ONE_RETURN + '/' + transferId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getBrandByBrandId (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_BRAND + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOrdersBySupplierId (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ORDER_BY_SUPPLIER_ID + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOrderItemsByOrderId (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_ORDER_ITEMS_BY_ORDER_ID, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProductsStat (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_PRODUCTS_STAT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getReviews (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_REVIEWS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getReviewsByProductId (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_REVIEWS_BY_PRODUCT_ID, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // getProductsFilter (query, body) {
  //   return new Promise(function (resolve, reject) {
  //     mainRequests
  //       .post(config.BASE_GET_PRODUCTS_BY_SUPPLIER_ID + query, body)
  //       .then(res => {
  //         resolve(res)
  //       })
  //       .catch(err => {
  //         reject(err)
  //       })
  //   })
  // },
  getSalesReport (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_SALES_REPORT + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPriceView (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_PRICE + '/' + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOrdersByDriverName (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_ORDERS_BY_DRIVER_NAME, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
