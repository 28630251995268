<template>
    <el-popover width="1200" placement="top-start">
      <div slot="reference" class="main">
        <img class="image" src="../assets/f.png" alt="">
        <p :style="isSelect ? 'color: #606266' : 'color: #bfcbd9'" class="text">Токтокмолл-н<br>ажилтантай холбогдох</p>
      </div>
    <header class="page-header ml20">
        <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2 style="color: #020202; font-family: monospace; font-size:">Токтокмолл-н ажилтаны жагсаалт ☎</h2>
        </el-col>
        </el-row>
    </header>
    <div class="pr20 mt20 table-filter mb10 ml10">
      <el-row :gutter="20" align="middle" type="flex">
        <el-col :span="12" class="ml20">
              <el-input placeholder="Хайх" class="input-with-select" @input="onSearch" v-model="search">
              </el-input>
            </el-col>
      </el-row>
    </div>
  <div class="container">
    <el-row type="flex">
      <div class="card"  v-for="(item, index) in toktokData" :key="index">
    <div class="content">
      <el-col :span="7" :offset="1">
      <div class="avatar">
        <img src="../assets/ss.png" alt="avatar">
      </div>
      </el-col>
      <el-col :span="16">
      <div class="details">
        <div>
          <h3>{{ item.name }}</h3>
        </div>
        <div>
          <i class="el-icon-phone"></i>
          <span>{{ item.phone }}</span>
        </div>
        <div>
          <i class="el-icon-s-cooperation"></i>
          <span>{{ item.major }}</span>
        </div>
        <div>
          <i class="el-icon-paperclip"></i>
          <span>{{ item.email }}</span>
        </div>
        <div>
          <i class="el-icon-location"></i>
          <span>{{ item.address }}</span>
        </div>
      </div>
      </el-col>
    </div>
  </div>
  <span class="emptyMessage" v-if="totalCount === 0">{{ emptyMessage }}</span>
  </el-row>
</div>
      <el-row :gutter="20">
        <el-pagination
        @current-change = "curentPageHandler"
        @size-change = "sizeChangeHandler"
        class="text-right mt10"
        background
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="[5, 20, 50, 100]"
        layout="total, sizes, prev, pager, next"
        :total="totalCount">
        </el-pagination>
      </el-row>
  </el-popover>
</template>
<script>
import services from '@/helpers/services.js'
export default {
  components: {
  },
  props: {
    isSelect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      search: '',
      activeTabStatus: 'all',
      pageSize: 5,
      totalCount: 0,
      currentPage: 1,
      toktokData: [],
      emptyMessage: null
    }
  },
  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingSearch = this.$route.query.search
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      this.search = incomingSearch
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.pageFrom = from
    this.pageSize = size
    this.getToktokEmployees(size, from, this.search)
  },
  methods: {
    sizeChangeHandler (size) {
      this.pageSize = size
      this.getToktokEmployees(this.pageSize, (this.pageSize * (this.currentPage - 1)), this.search)
    },

    curentPageHandler (current) {
      this.currentPage = current
      this.getToktokEmployees(this.pageSize, (this.pageSize * (this.currentPage - 1)), this.search)
    },
    onSearch () {
      this.getToktokEmployees(this.pageSize, (this.pageSize * (this.currentPage - 1)), this.search)
      if (this.totalCount === 0) {
        this.emptyMessage = 'Өгөгдөл байхгүй байна :(('
      }
    },
    async getToktokEmployees (size, from) {
      this.isLoading = true
      const query = '?search_text=' + this.search + '&size=' + size + '&from=' + from
      services.getToktokEmployees(query).then(response => {
        if (response.status === 'success') {
          this.toktokData = response.data
          this.totalCount = response.total
          this.isLoading = false
        }
      })
    }
  }
}
</script>
<style scoped>
.emptyMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 500px;
  font-weight: bold;
}
.main{
  position: fixed;
  bottom: 20px;
  font-family: Arial, Helvetica, sans-serif !important;
  width: auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  z-index: 9999;
  left: 80px;
}
.image{
  height: 60px;
  width: 65px;
  right: 130px;
  bottom: 20px;
  cursor: pointer;
}
.image:hover{
  height: 62px;
  width: 67px;
}
.text{
  top: 10px;
  right: 30px;
  cursor: pointer;
  color: #606266;
  font-weight: bold;
  font-family:monospace;
  line-height:1;
  text-align: center;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.container {
    margin-right: 20px;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 300px;
    display: flex;
    overflow-x: auto;
}

.card {
    margin-left: 30px;
    padding-right: 20px;
    position: relative;
    width: 200px;
    height: 200px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 25px;
    /* box-shadow: 5px 5px 40px 5px rgba(0, 0, 0, 0.1); */
    box-shadow: 0 25px 25px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    transition: 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

}

.card:hover {
    transform: scale(1.1);
    background: rgb(255, 255, 255);
    z-index: 9999999;
}

.card::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
}

.card .content {
    font-size: 12px;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: -20px;
    gap: 10px;
}

.card .content .avatar {
    margin-top: -80px;
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
}

.card .content .avatar img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card .content .details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #111;
}

.card .content .details div {
    display:  flex;
    align-items: center;
    gap: 10px;
}

.card .content .details ion-icon {
    font-size: 1.5em;
}
</style>
