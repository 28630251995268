import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import locale from 'element-ui/lib/locale/lang/mn'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import * as VueGoogleMaps from 'vue2-google-maps'
import TreeSelector from 'vue-tree-selector'
import VueApexCharts from 'vue-apexcharts'
import VueBarcode from 'vue-barcode'
import VueHtml2pdf from 'vue-html2pdf'
import { io } from 'socket.io-client'
import { getToken } from './utils/auth'
import CustomPlugin from './plugins'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCopyright, faBagShopping, faHandshake, faTruck, faHandshakeSimpleSlash, faStarHalfStroke, faWarehouse, faPenToSquare, faBarcode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueExcelXlsx from 'vue-excel-xlsx'
import VueScheduler from 'v-calendar-scheduler'
import 'v-calendar-scheduler/lib/main.css'

library.add(faCopyright, faBagShopping, faHandshake, faTruck, faHandshakeSimpleSlash, faStarHalfStroke, faWarehouse, faPenToSquare, faBarcode)

Vue.use(VueScheduler)
Vue.use(VueExcelXlsx)
Vue.use(VueHtml2pdf)

Vue.use(TreeSelector)
// your config here

Vue.use(ElementUI, { locale })
Vue.use(VueApexCharts)
Vue.use(CustomPlugin)
Vue.component('apexchart', VueApexCharts)
Vue.component('barcode', VueBarcode)
Vue.component('font-awesome-icon', FontAwesomeIcon)
// or using the defaults with no stylesheet
Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: 'AIzaSyB8wrdyLVTdvKFwWqAbzC-tlLgFoegQsDA',
    region: 'MN',
    language: 'mn',
    libraries: 'places'
  }
})
Vue.config.productionTip = false
Amplify.configure(awsconfig)
require('./assets/main.scss')

new Vue({
  router,
  data () {
    return {
      socket: null
    }
  },
  methods: {
    async initSocket () {
      const token = await getToken()
      const socket = io('chat-api.toktokmall.mn', {
        extraHeaders: {
          Authorization: token
        }
      })
      this.socket = socket
    },
    async socketTokenRefresher () {
      const token = await getToken()
      this.socket.io.opts.extraHeaders.Authorization = token
      setInterval(() => {
        this.socketTokenRefresher()
      }, 600000)
    }
  },
  store,
  render: h => h(App)
}).$mount('#app')
