import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import { handlers } from '../helpers/custom.js'

function getUser () {
  return Auth.currentAuthenticatedUser()
    .then(user => {
      if (user && user.signInUserSession) {
        return user
      } else {
        return null
      }
    })
    .catch(err => {
      console.log(err)
    })
}

// function getGroups () {
//   return Auth.currentAuthenticatedUser()
//     .then(user => {
//       if (user && user.signInUserSession) {
//         return user.signInUserSession.accessToken.payload['cognito:groups']
//       } else {
//         return null
//       }
//     })
//     .catch(err => {
//       // eslint-disable-next-line
//     console.log(err)
//     })
// }

function getUserName () {
  return Auth.currentAuthenticatedUser()
    .then(user => {
      if (user && user.signInUserSession) {
        return user.attributes.email
      } else {
        return null
      }
    })
    .catch(err => {
      handlers.alertReporter(err, 'error')
    })
}

function getSupplierId () {
  return Auth.currentAuthenticatedUser()
    .then(user => {
      if (user && user.signInUserSession) {
        const tempResponse = user.attributes['custom:supplier_ids']
        if (tempResponse) {
          const supplierId = JSON.parse(tempResponse)
          return supplierId
        } else {
          return null
        }
      } else {
        return null
      }
    })
    .catch(err => {
      handlers.alertReporter(err, 'error')
    })
}
async function getToken () {
  return await Auth.currentSession()
    .then(session => {
      if (session) {
        return session.idToken.jwtToken
      } else {
        return null
      }
    })
    .catch(err => {
      // eslint-disable-next-line
    console.log(err)
    })
}

function signUp (username, email, password) {
  return Auth.signUp({
    username,
    password,
    attributes: {
      name: username,
      email: email
    }
  })
    .then(data => {
      // AmplifyEventBus.$emit('localUser', data.user)
      // if (data.userConfirmed === false) {
      //   handlers.alertReporter('Хэрэглэгчийг баталгаажуулаагүй байна. Админтай холбогдоно уу!', 'error')
      //   AmplifyEventBus.$emit('authState', 'signIn')
      // } else {
      //   AmplifyEventBus.$emit('authState', 'signedIn')
      // }
      return data
    })
    .catch(err => {
      userErrorMessageHandler(err)
    })
}
export async function sendForgotPassCode (username) {
  const data = await Auth.forgotPassword(username)
  return data
}

export async function changePasswordSumbit (payload) {
  const data = await Auth.forgotPasswordSubmit(payload.username, payload.code, payload.new_password)
  return data
}

async function confirmSignUp (username, code) {
  try {
    await Auth.confirmSignUp(username, code).then(data => {
      return data
    })
  } catch (error) {
    userErrorMessageHandler(error)
    throw new Error(error)
  }
}

// function confirmSignUp (username, code) {
//   return Auth.confirmSignUp(username, code)
//     .then(data => {
//       AmplifyEventBus.$emit('authState', 'signIn')
//       return data // 'SUCCESS'
//     })
//     .catch(err => {
//       // eslint-disable-next-line
//     console.log(err)
//     })
// }

// function resendSignUp(username) {
//   return Auth.resendSignUp(username)
//     .then(() => {
//       return "SUCCESS";
//     })
//     .catch(err => {
//       // eslint-disable-next-line
//     console.log(err)
//     });
// }

async function signIn (username, password) {
  try {
    const user = await Auth.signIn(username, password)
    if (user) {
      AmplifyEventBus.$emit('authState', 'signedIn')
    }
  } catch (err) {
    userErrorMessageHandler(err)
  }
}

function userErrorMessageHandler (err) {
  if (err.code === 'UserNotConfirmedException') {
    handlers.alertReporter('Хэрэглэгчийг баталгаажуулаагүй байна. Админтай холбогдоно уу!', 'error')
  } else if (err.code === 'PasswordResetRequiredException') {
    handlers.alertReporter('Хэрэглэгчийг нууц үгийг сэргээх шаардлагтай.!', 'error')
  } else if (err.code === 'NotAuthorizedException') {
    handlers.alertReporter('Хэрэглэгчийн нууц үг эсвэл имайл буруу байна.!!', 'error')
  } else if (err.code === 'UserNotFoundException') {
    handlers.alertReporter('Бүртгэлтэй хэрэглэгч олдсонгүй!!', 'error')
  } else if (err.code === 'InvalidParameterException') {
    handlers.alertReporter(err.message, 'error')
  } else if (err.code === 'CodeMismatchException') {
    handlers.alertReporter('Алдаа гарлаа.!! Баталгаажуулах код буруу байна', 'error')
  } else {
    handlers.alertReporter(`Алдаа гарлаа.!! ${err.message}`, 'error')
  }
}

function signOut () {
  return Auth.signOut()
    .then(data => {
      AmplifyEventBus.$emit('authState', 'signedOut')
      return data
    })
    .catch(err => {
      handlers.alertReporter(err, 'error')
    })
}

export {
  getUser,
  signUp,
  confirmSignUp,
  // resendSignUp,
  signIn,
  signOut,
  // ,
  getToken,
  // getGroups,
  getUserName,
  getSupplierId
}
