var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-aside',{class:_vm.isSun ? 'main-sidebar' : 'main-sidebar1'},[_c('ul',{staticClass:"main-menu"},[_c('li',{class:((_vm.$route.name === 'homePage') ? 'active': '')},[_c('router-link',{attrs:{"to":"/homePage"}},[_c('home-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',[_vm._v("Нүүр")])],1)],1),_c('li',{class:(( ['salesReport', 'cancelReport'].includes(_vm.$route.name)) ? 'active': '')},[_c('router-link',{attrs:{"to":"/sales-report"}},[_c('note-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Тайлан")])],1),_c('ul',[_c('li',{class:(( ['salesReport'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/sales-report"}},[_c('span',{staticClass:"text"},[_vm._v("Борлуулалт")])])],1),_c('li',{class:(( ['cancelReport'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/cancel-report"}},[_c('span',{staticClass:"text"},[_vm._v("Цуцлалт")])])],1)])],1),_c('li',{class:(( [
      'products',
      'updateProduct',
      'addProduct',
      'inventory',
      'stockDetail',
       'remainderReport'
      ].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/products"}},[_c('format-list-bulleted-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Бараа материал")])],1),_c('ul',[_c('li',{class:(( [
            'products',
            'updateProduct',
            'addProduct'
            ].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/products"}},[_c('span',{staticClass:"text"},[_vm._v("Бүтээгдэхүүн")])])],1),_c('li',{class:(( [
            'inventory',
            'stockDetail'
            ].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/inventory"}},[_c('span',{staticClass:"text"},[_vm._v("Хувилбарууд")])])],1),_c('li',{class:(( ['remainderReport'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/remainder-report"}},[_c('span',{staticClass:"text"},[_vm._v("Үлдэгдэл")])])],1)])],1),_c('li',{class:(( [
        'orderList'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/order"}},[_c('order-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Нийт захиалгууд")])],1)],1),_c('li',{class:(( [
        'clientContactEdit',
        'clientContactAdd',
        'clientContactList'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/client-contact-list"}},[_c('contacts-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Харилцагч")])],1)],1),_c('li',{class:(( [
        'Warehouse',
        'AddWarehouse',
        'UpdateWarehouse'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/warehouse"}},[_c('warehouse',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Агуулах")])],1)],1)]),_c('i',{class:_vm.isSun ? 'el-icon-moon position mr5 iconSun' : 'el-icon-sunny mr5 position iconDark',on:{"click":function($event){_vm.isSun = !_vm.isSun}}}),_c('contact-list',{attrs:{"isSelect":_vm.isSun}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }